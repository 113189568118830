/* You can add global styles to this file, and also import other style files */
@import "assets/styles/colors";
@import "assets/styles/form";
@import url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond:ital,wght@0,300;0,400;0,600;0,700;1,300;1,400;1,500;1,600;1,700&family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;1,300;1,400;1,500;1,600&display=swap');

body {
  p {
    font-family: 'Open Sans', sans-serif;
    margin-bottom: 0px;
    color: #666666;
  }
}
.btn {
  border-radius: 0 !important;
}

.btn-dark-purple, .btn-dark-purple:hover {
  background-color: $dark-purple !important;;
  color: #ffffff !important;;
}

.btn-yellow, .btn-yellow:hover {
  background-color: $yellow !important;
  color: #fff !important;;
}

.text-purple {
  color: $purple !important;;
}
.text-yellow {
  color: $yellow !important;;
}
.bg-purple {
  background-color: $purple !important;;
}

.border-shadow{
  border-radius: 5px;
  box-shadow: 0px 3px 6px #00000029;
  overflow: hidden;
}

.bg-grey{
  background-color: #F3F3F3 !important;;
}

.text-cormorant {
  font-family: 'Cormorant Garamond', serif;
}


@media (max-width: 992px) {
  .rounded {
    border-radius: 0px !important;
  }
}

